module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":760,"quality":60,"linkImagesToOriginal":false,"withWebp":true,"loading":"lazy","showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","tracedSVG":false,"disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Benito Arruñada Website","short_name":"B. Arruñada","start_url":"/","lang":"en","background_color":"#efebe5","theme_color":"#efebe5","display":"minimal-ui","icon":"src/images/arrunada-icon.jpg","localize":[{"start_url":"/es/","lang":"es","name":"Página Web de Benito Arruñada","short_name":"B. Arruñada"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cd9d98bc99fea2a57a50bff01b53eb17"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-3356949-1","head":false,"anonymize":true,"cookieDomain":"arrunada.org","respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
